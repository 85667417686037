import useQueryState from './useQueryState';
import { TABLE_LIMITS } from '@/utils/constants';
import { SortDirections } from '@/types/client';
export var parseSometimesNull = function(query) {
    if (query === 'null' || query === 'undefined') {
        return null;
    }
    return query;
};
export function useLimitQueryState() {
    return useQueryState('limit', {
        parse: function(query) {
            var parsed = parseInt(query, 10);
            return TABLE_LIMITS.includes(parsed) ? parsed : TABLE_LIMITS[1];
        },
        serialize: function(limit) {
            return limit.toString();
        },
        defaultValue: TABLE_LIMITS[1]
    });
}
export function useSortDirectionQueryState() {
    var defaultValue = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : SortDirections.ASC;
    return useQueryState('direction', {
        defaultValue: defaultValue,
        parse: parseSometimesNull
    });
}
export function usePageQueryState() {
    return useQueryState('page', {
        parse: function(query) {
            return Math.max(-1, parseInt(query, 10) - 1);
        },
        serialize: function(offset) {
            return Math.max(1, offset + 1).toString();
        },
        defaultValue: 0
    });
}
export function useSearchQueryState() {
    return useQueryState('search', {
        defaultValue: ''
    });
}
