// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayWinnerModal-module__confetti-UUurc {
  position: 'absolute';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999 !important;
}

.nightbot-GiveawayWinnerModal-module__messages-MJmOu {
  height: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayWinnerModal.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".confetti {\n  position: 'absolute';\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 9999 !important;\n}\n\n.messages {\n  height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confetti": `nightbot-GiveawayWinnerModal-module__confetti-UUurc`,
	"messages": `nightbot-GiveawayWinnerModal-module__messages-MJmOu`
};
export default ___CSS_LOADER_EXPORT___;
