import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { checkboxAnatomy } from '@chakra-ui/anatomy';
// eslint-disable-next-line @typescript-eslint/unbound-method
var _createMultiStyleConfigHelpers = createMultiStyleConfigHelpers(checkboxAnatomy.keys), definePartsStyle = _createMultiStyleConfigHelpers.definePartsStyle, defineMultiStyleConfig = _createMultiStyleConfigHelpers.defineMultiStyleConfig;
var menuStyles = definePartsStyle({
    label: {
        width: '100%'
    }
});
export default defineMultiStyleConfig({
    baseStyle: menuStyles
});
