import React from 'react';
import { handleNativeEvent } from '@/lib/native-events';
function NativeProvider(param) {
    var children = param.children;
    React.useEffect(function() {
        window.addEventListener('message', handleNativeEvent);
        return function() {
            window.removeEventListener('message', handleNativeEvent);
        };
    }, []);
    return children;
}
export default NativeProvider;
