import { useLocation, useNavigate } from 'react-router-dom';
function useBackButton(fallbackParams) {
    var navigate = useNavigate();
    var location = useLocation();
    var canGoBack = location.key !== 'default';
    return {
        canGoBack: canGoBack,
        back: function() {
            return canGoBack ? navigate(-1) : navigate(fallbackParams);
        }
    };
}
export default useBackButton;
