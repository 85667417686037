import { extendTheme, withDefaultColorScheme, withDefaultVariant } from '@chakra-ui/react';
import buttonStyles from '@/utils/theme/button';
import switchStyles from '@/utils/theme/switch';
import menuStyles from '@/utils/theme/menu';
import selectStyles from '@/utils/theme/select';
import modalStyles from '@/utils/theme/modal';
import checkboxStyles from '@/utils/theme/checkbox';
import '@fontsource/lexend';
export var theme = extendTheme({
    config: {
        useSystemColorMode: false,
        initalColorMode: 'dark'
    },
    fonts: {
        heading: "'Lexend', sans-serif",
        body: "'Lexend', sans-serif"
    },
    components: {
        Button: buttonStyles,
        IconButton: buttonStyles,
        Link: {
            baseStyle: {
                color: 'textAccent'
            }
        },
        Switch: switchStyles,
        Menu: menuStyles,
        Select: selectStyles,
        Modal: modalStyles,
        Checkbox: checkboxStyles
    },
    colors: {
        black: '#16171c',
        white: '#ffffff',
        gray: {
            '50': '#f9fafa',
            '100': '#f1f1f2',
            '150': '#f6f6f6',
            '200': '#e7e7e8',
            '300': '#d3d4d5',
            '400': '#acadae',
            '500': '#7e7f82',
            '600': '#535458',
            '700': '#35373b',
            '750': '#26282d',
            '800': '#1e2025',
            '900': '#18191e'
        },
        purple: {
            '50': '#f8f5ff',
            '100': '#e5d9fe',
            '200': '#d1bdfd',
            '300': '#b593fc',
            '400': '#a075fb',
            '500': '#8157db',
            '600': '#6c49b7',
            '700': '#583c96',
            '800': '#48317b',
            '900': '#35245b'
        },
        pink: {
            '50': '#fff5fa',
            '100': '#fed6eb',
            '200': '#fdb4da',
            '300': '#fc81c0',
            '400': '#f160ab',
            '500': '#c74f8d',
            '600': '#aa4479',
            '700': '#8a3762',
            '800': '#6c2b4c',
            '900': '#4f2038'
        },
        orange: {
            '50': '#fffaf5',
            '100': '#feead7',
            '200': '#fdd1a7',
            '300': '#f8ab63',
            '400': '#d69455',
            '500': '#b97f4a',
            '600': '#9c6b3e',
            '700': '#7c5531',
            '800': '#624327',
            '900': '#503720'
        },
        yellow: {
            '50': '#fffefa',
            '100': '#fef9e2',
            '200': '#fdeda9',
            '300': '#fade64',
            '400': '#e5cb5b',
            '500': '#bca74b',
            '600': '#97863c',
            '700': '#75682f',
            '800': '#584e23',
            '900': '#48401d'
        },
        green: {
            '50': '#f0fff7',
            '100': '#a6fdd0',
            '200': '#5fefa5',
            '300': '#55d593',
            '400': '#4aba80',
            '500': '#40a06e',
            '600': '#35845b',
            '700': '#296747',
            '800': '#22543a',
            '900': '#1c4530'
        },
        teal: {
            '50': '#e7fefe',
            '100': '#7efcf9',
            '200': '#5de9e6',
            '300': '#53d0cd',
            '400': '#46b1ae',
            '500': '#3c9694',
            '600': '#317a78',
            '700': '#265f5d',
            '800': '#1f4f4e',
            '900': '#1a4140'
        },
        cyan: {
            '50': '#effcff',
            '100': '#b9f3fd',
            '200': '#96edfc',
            '300': '#6ae5fb',
            '400': '#56c3d7',
            '500': '#4fb3c5',
            '600': '#47a1b2',
            '700': '#3b8593',
            '800': '#306e79',
            '900': '#25555d'
        },
        blue: {
            '50': '#eff7ff',
            '100': '#c3e1fd',
            '200': '#96cbfc',
            '300': '#63b1fa',
            '400': '#5698d7',
            '500': '#4983b8',
            '600': '#3d6d9a',
            '700': '#2f5476',
            '800': '#264460',
            '900': '#1f384e'
        },
        primary: {
            '50': '#f4f7ff',
            '100': '#d2e1fe',
            '200': '#aac6fd',
            '300': '#79a5fc',
            '400': '#6192f3',
            '500': '#527bcd',
            '600': '#4568ad',
            '700': '#37548b',
            '800': '#2f4775',
            '900': '#223355'
        },
        primaryAlpha: {
            '50': 'rgba(82, 123, 205, 0.02)',
            '100': 'rgba(82, 123, 205, 0.04)',
            '200': 'rgba(82, 123, 205, 0.08)',
            '300': 'rgba(82, 123, 205, 0.16)',
            '400': 'rgba(82, 123, 205, 0.24)',
            '500': 'rgba(82, 123, 205, 0.32)',
            '600': 'rgba(82, 123, 205, 0.48)',
            '700': 'rgba(82, 123, 205, 0.64)',
            '800': 'rgba(82, 123, 205, 0.80)',
            '900': 'rgba(82, 123, 205, 0.92)'
        },
        purpleAlpha: {
            '50': 'rgba(129, 87, 219, 0.02)',
            '100': 'rgba(129, 87, 219, 0.04)',
            '200': 'rgba(129, 87, 219, 0.08)',
            '300': 'rgba(129, 87, 219, 0.16)',
            '400': 'rgba(129, 87, 219, 0.24)',
            '500': 'rgba(129, 87, 219, 0.32)',
            '600': 'rgba(129, 87, 219, 0.48)',
            '700': 'rgba(129, 87, 219, 0.64)',
            '800': 'rgba(129, 87, 219, 0.80)',
            '900': 'rgba(129, 87, 219, 0.92)'
        },
        greenAlpha: {
            '50': 'rgba(64, 160, 110, 0.02)',
            '100': 'rgba(64, 160, 110, 0.04)',
            '200': 'rgba(64, 160, 110, 0.08)',
            '300': 'rgba(64, 160, 110, 0.16)',
            '400': 'rgba(64, 160, 110, 0.24)',
            '500': 'rgba(64, 160, 110, 0.32)',
            '600': 'rgba(64, 160, 110, 0.48)',
            '700': 'rgba(64, 160, 110, 0.64)',
            '800': 'rgba(64, 160, 110, 0.80)',
            '900': 'rgba(64, 160, 110, 0.92)'
        }
    },
    semanticTokens: {
        colors: {
            textPrimary: {
                _light: 'gray.800',
                _dark: 'gray.100'
            },
            textSecondary: {
                _light: 'gray.500',
                _dark: 'gray.400'
            },
            textTertiary: {
                _light: 'gray.400',
                _dark: 'gray.500'
            },
            textInverse: {
                _light: 'gray.100',
                _dark: 'gray.900'
            },
            textAccent: {
                _light: 'primary.700',
                _dark: 'primary.200'
            },
            textActive: {
                _light: 'primary.600',
                _dark: 'primary.300'
            },
            textLink: {
                _light: 'primary.500',
                _dark: 'primary.300'
            },
            textError: {
                _light: 'red.500',
                _dark: 'red.300'
            },
            backgroundPrimary: {
                _light: 'white',
                _dark: 'gray.800'
            },
            backgroundSecondary: {
                _light: 'gray.100',
                _dark: 'gray.900'
            },
            backgroundTertiary: {
                _light: 'gray.50',
                _dark: 'black'
            },
            backgroundAccent: {
                _light: 'primary.100',
                _dark: 'primary.800'
            },
            backgroundActive: {
                _light: 'primary.100',
                _dark: 'primary.900'
            },
            backgroundInverse: {
                _light: 'gray.900',
                _dark: 'gray.100'
            },
            borderPrimary: {
                _light: 'blackAlpha.300',
                _dark: 'whiteAlpha.300'
            },
            borderSecondary: {
                _light: 'blackAlpha.100',
                _dark: 'whiteAlpha.100'
            },
            borderTertiary: {
                _light: 'blackAlpha.50',
                _dark: 'whiteAlpha.50'
            },
            backgroundHover: {
                _light: 'blackAlpha.50',
                _dark: 'whiteAlpha.50'
            },
            success: {
                _light: 'green.500',
                _dark: 'green.300'
            },
            negative: {
                _light: 'red.500',
                _dark: 'red.300'
            },
            intermediate: {
                _light: 'orange.300',
                _dark: 'orange.300'
            },
            gold: {
                _light: 'yellow.300',
                _dark: 'yellow.500'
            },
            brandDiscordBlurple: '#5865F2'
        }
    }
}, withDefaultVariant({
    variant: 'outline',
    components: [
        'Button',
        'IconButton'
    ]
}), withDefaultVariant({
    variant: 'filled',
    components: [
        'Input',
        'Select',
        'Textarea',
        'NumberInput'
    ]
}), withDefaultColorScheme({
    colorScheme: 'primary',
    components: [
        'Switch'
    ]
}));
