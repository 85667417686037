import EventEmitter from 'events';
import { NativeEventNames } from '@/utils/constants';
import NativeStore from '@/stores/NativeStore';
var nativeEvents = new EventEmitter();
export function sendNativeEvent(event) {
    var _NativeStore_getState = NativeStore.getState(), source = _NativeStore_getState.source, origin = _NativeStore_getState.origin;
    if (source == null || origin == null) {
        return;
    }
    source.postMessage(event, {
        targetOrigin: origin
    });
}
export function handleNativeEvent(param) {
    var event = param.data, source = param.source, origin = param.origin;
    if (typeof event === 'string') {
        return;
    }
    if (event.name === NativeEventNames.NEGOTIATION) {
        var _NativeStore_getState = NativeStore.getState(), setSource = _NativeStore_getState.setSource, setOrigin = _NativeStore_getState.setOrigin, setNegotiated = _NativeStore_getState.setNegotiated;
        setSource(source);
        setOrigin(origin);
        setNegotiated(true);
        sendNativeEvent({
            name: NativeEventNames.NEGOTIATED,
            data: null
        });
    }
    if (event.name === NativeEventNames.SETTINGS) {
        var setSettings = NativeStore.getState().setSettings;
        var newSettings = new Map();
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = event.data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var _step_value = _step.value, key = _step_value.key, value = _step_value.value;
                newSettings.set(key, value);
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
        setSettings(newSettings);
    }
    nativeEvents.emit(event.name, event.data);
}
export default nativeEvents;
