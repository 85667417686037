import { AuthProviders } from '@/types/server';
import { StorageKeys } from '@/utils/constants';
import { sanitizePathname } from '@/utils/url';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
var LEGACY_ACCESS_TOKEN = localStorage.getItem(StorageKeys.LEGACY_ACCESS_TOKEN);
var useAuthenticationStore = create()(persist(function(set) {
    return {
        user: null,
        provider: AuthProviders.TWITCH,
        accessToken: LEGACY_ACCESS_TOKEN !== null && LEGACY_ACCESS_TOKEN !== void 0 ? LEGACY_ACCESS_TOKEN : null,
        deviceCode: null,
        isLoggedIn: false,
        authState: null,
        redirectPath: null,
        unverified2FA: false,
        setAuthState: function(authState) {
            return set({
                authState: authState
            });
        },
        setLoggedIn: function(isLoggedIn) {
            return set({
                isLoggedIn: isLoggedIn
            });
        },
        setProvider: function(provider) {
            return set({
                provider: provider
            });
        },
        setAccessToken: function(accessToken) {
            return set({
                accessToken: accessToken
            });
        },
        setRedirectPath: function(redirectPath) {
            return set({
                redirectPath: sanitizePathname(redirectPath)
            });
        },
        setUnverified2FA: function(unverified2FA) {
            return set({
                unverified2FA: unverified2FA
            });
        }
    };
}, {
    name: StorageKeys.AUTHENTICATION_STORE,
    partialize: function(param) {
        var accessToken = param.accessToken, authState = param.authState, provider = param.provider, redirectPath = param.redirectPath;
        return {
            accessToken: accessToken,
            provider: provider,
            redirectPath: redirectPath,
            authState: authState
        };
    }
}));
export default useAuthenticationStore;
