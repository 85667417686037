function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import API from '@/utils/api';
import { APIRoutes } from '@/utils/constants';
export function getSongRequestQueue(channelId) {
    return _getSongRequestQueue.apply(this, arguments);
}
function _getSongRequestQueue() {
    _getSongRequestQueue = _async_to_generator(function(channelId) {
        var _ref, _ref_data, queue, _currentSong;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.SONG_REQUESTS_QUEUE, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, queue = _ref_data.queue, _currentSong = _ref_data._currentSong;
                    return [
                        2,
                        {
                            queue: queue,
                            current: _currentSong
                        }
                    ];
            }
        });
    });
    return _getSongRequestQueue.apply(this, arguments);
}
export function getPlaylist(channelId, params) {
    return _getPlaylist.apply(this, arguments);
}
function _getPlaylist() {
    _getPlaylist = _async_to_generator(function(channelId, params) {
        var _ref, _ref_data, playlist, _total, _offset, _limit;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.SONG_REQUESTS_PLAYLIST, {
                            channelId: channelId,
                            searchParams: params
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, playlist = _ref_data.playlist, _total = _ref_data._total, _offset = _ref_data._offset, _limit = _ref_data._limit;
                    return [
                        2,
                        {
                            playlist: playlist,
                            total: _total,
                            offset: _offset,
                            limit: _limit
                        }
                    ];
            }
        });
    });
    return _getPlaylist.apply(this, arguments);
}
export function createPlaylistItem(channelId, query) {
    return _createPlaylistItem.apply(this, arguments);
}
function _createPlaylistItem() {
    _createPlaylistItem = _async_to_generator(function(channelId, query) {
        var _ref, item;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.SONG_REQUESTS_PLAYLIST, {
                            channelId: channelId,
                            body: {
                                q: query
                            }
                        })
                    ];
                case 1:
                    _ref = _state.sent(), item = _ref.data.item;
                    return [
                        2,
                        item
                    ];
            }
        });
    });
    return _createPlaylistItem.apply(this, arguments);
}
export function createSongRequest(channelId, query, token) {
    return _createSongRequest.apply(this, arguments);
}
function _createSongRequest() {
    _createSongRequest = _async_to_generator(function(channelId, query, token) {
        var _ref, item;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.SONG_REQUESTS_QUEUE, {
                            channelId: channelId,
                            searchParams: token != null ? {
                                has_captcha: 'true'
                            } : undefined,
                            body: {
                                captcha: token,
                                q: query
                            },
                            retry: false
                        })
                    ];
                case 1:
                    _ref = _state.sent(), item = _ref.data.item;
                    return [
                        2,
                        item
                    ];
            }
        });
    });
    return _createSongRequest.apply(this, arguments);
}
export function promoteSongRequest(channelId, trackId) {
    return _promoteSongRequest.apply(this, arguments);
}
function _promoteSongRequest() {
    _promoteSongRequest = _async_to_generator(function(channelId, trackId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.SONG_REQUESTS_QUEUE_ITEM_PROMOTE(trackId), {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _promoteSongRequest.apply(this, arguments);
}
export function deleteSongRequest(channelId, trackId) {
    return _deleteSongRequest.apply(this, arguments);
}
function _deleteSongRequest() {
    _deleteSongRequest = _async_to_generator(function(channelId, trackId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.SONG_REQUESTS_QUEUE_ITEM(trackId), {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _deleteSongRequest.apply(this, arguments);
}
export function clearSongRequestQueue(channelId) {
    return _clearSongRequestQueue.apply(this, arguments);
}
function _clearSongRequestQueue() {
    _clearSongRequestQueue = _async_to_generator(function(channelId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.SONG_REQUESTS_QUEUE, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _clearSongRequestQueue.apply(this, arguments);
}
export function getSongRequestSettings(channelId) {
    return _getSongRequestSettings.apply(this, arguments);
}
function _getSongRequestSettings() {
    _getSongRequestSettings = _async_to_generator(function(channelId) {
        var _ref, _ref_data, settings, providers, playlists;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.SONG_REQUESTS, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, settings = _ref_data.settings, providers = _ref_data.providers, playlists = _ref_data.playlists;
                    return [
                        2,
                        {
                            settings: settings,
                            providers: providers,
                            playlists: playlists
                        }
                    ];
            }
        });
    });
    return _getSongRequestSettings.apply(this, arguments);
}
export function updateSongRequestSettings(channelId, settings) {
    return _updateSongRequestSettings.apply(this, arguments);
}
function _updateSongRequestSettings() {
    _updateSongRequestSettings = _async_to_generator(function(channelId, settings) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.put(APIRoutes.SONG_REQUESTS, {
                            channelId: channelId,
                            body: settings
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _updateSongRequestSettings.apply(this, arguments);
}
export function clearSongRequestPlaylist(channelId) {
    return _clearSongRequestPlaylist.apply(this, arguments);
}
function _clearSongRequestPlaylist() {
    _clearSongRequestPlaylist = _async_to_generator(function(channelId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.SONG_REQUESTS_PLAYLIST, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _clearSongRequestPlaylist.apply(this, arguments);
}
export function deletePlaylistItem(channelId, trackId) {
    return _deletePlaylistItem.apply(this, arguments);
}
function _deletePlaylistItem() {
    _deletePlaylistItem = _async_to_generator(function(channelId, trackId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.SONG_REQUESTS_PLAYLIST_ITEM(trackId), {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _deletePlaylistItem.apply(this, arguments);
}
export function playSongRequestQueueItem(channelId, trackId) {
    return _playSongRequestQueueItem.apply(this, arguments);
}
function _playSongRequestQueueItem() {
    _playSongRequestQueueItem = _async_to_generator(function(channelId, trackId) {
        var _ref, item;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.SONG_REQUESTS_QUEUE_ITEM_PLAY(trackId), {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), item = _ref.data.item;
                    return [
                        2,
                        item
                    ];
            }
        });
    });
    return _playSongRequestQueueItem.apply(this, arguments);
}
export function createSongRequestFromPlaylistItem(channelId, trackId) {
    return _createSongRequestFromPlaylistItem.apply(this, arguments);
}
function _createSongRequestFromPlaylistItem() {
    _createSongRequestFromPlaylistItem = _async_to_generator(function(channelId, trackId) {
        var _ref, item;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.SONG_REQUESTS_QUEUE, {
                            body: {
                                fromPlaylist: true,
                                q: trackId
                            },
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), item = _ref.data.item;
                    return [
                        2,
                        item
                    ];
            }
        });
    });
    return _createSongRequestFromPlaylistItem.apply(this, arguments);
}
export function importPlaylistFromUrl(channelId, playlistUrl) {
    return _importPlaylistFromUrl.apply(this, arguments);
}
function _importPlaylistFromUrl() {
    _importPlaylistFromUrl = _async_to_generator(function(channelId, playlistUrl) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.SONG_REQUESTS_PLAYLIST_IMPORT, {
                            channelId: channelId,
                            body: {
                                url: playlistUrl
                            }
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _importPlaylistFromUrl.apply(this, arguments);
}
export function reorderSongRequestQueue(channelId, order) {
    return _reorderSongRequestQueue.apply(this, arguments);
}
function _reorderSongRequestQueue() {
    _reorderSongRequestQueue = _async_to_generator(function(channelId, order) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.patch(APIRoutes.SONG_REQUESTS_QUEUE_ORDER, {
                            channelId: channelId,
                            body: {
                                order: order
                            }
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _reorderSongRequestQueue.apply(this, arguments);
}
