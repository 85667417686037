function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import ChannelStore from '@/stores/ChannelStore';
import API, { HTTPError } from '@/utils/api';
import { APIRoutes } from '@/utils/constants';
import AuthenticationStore from '@/stores/AuthenticationStore';
export function updateChannel(channelId) {
    return _updateChannel.apply(this, arguments);
}
function _updateChannel() {
    _updateChannel = _async_to_generator(function(channelId) {
        var user, _ChannelStore_getState, prevChannel, setChannel, _prevChannel__id, _ref, channel, error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    user = AuthenticationStore.getState().user;
                    _ChannelStore_getState = ChannelStore.getState(), prevChannel = _ChannelStore_getState.channel, setChannel = _ChannelStore_getState.setChannel;
                    if (channelId === undefined) {
                        ;
                        channelId = (_prevChannel__id = prevChannel === null || prevChannel === void 0 ? void 0 : prevChannel._id) !== null && _prevChannel__id !== void 0 ? _prevChannel__id : user === null || user === void 0 ? void 0 : user._id;
                    }
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        3,
                        ,
                        4
                    ]);
                    return [
                        4,
                        API.get(APIRoutes.CHANNEL, {
                            channelId: channelId
                        })
                    ];
                case 2:
                    _ref = _state.sent(), channel = _ref.data.channel;
                    setChannel(channel);
                    return [
                        3,
                        4
                    ];
                case 3:
                    error = _state.sent();
                    if (!_instanceof(error, HTTPError)) {
                        throw error;
                    }
                    if (error.statusCode === 403 && user != null && channelId !== user._id) {
                        updateChannel(user._id);
                    }
                    return [
                        3,
                        4
                    ];
                case 4:
                    return [
                        2
                    ];
            }
        });
    });
    return _updateChannel.apply(this, arguments);
}
export function getChannelByName(provider, channelName) {
    return _getChannelByName.apply(this, arguments);
}
function _getChannelByName() {
    _getChannelByName = _async_to_generator(function(provider, channelName) {
        var _ref, channel;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.CHANNELS_PROVIDER_NAME(provider, channelName))
                    ];
                case 1:
                    _ref = _state.sent(), channel = _ref.data.channel;
                    return [
                        2,
                        channel
                    ];
            }
        });
    });
    return _getChannelByName.apply(this, arguments);
}
export function getChannels() {
    return _getChannels.apply(this, arguments);
}
function _getChannels() {
    _getChannels = _async_to_generator(function() {
        var _ref, channels;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    ChannelStore.setState({
                        managedChannelsLoading: true
                    });
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        ,
                        3,
                        4
                    ]);
                    return [
                        4,
                        API.get(APIRoutes.ME_CHANNELS)
                    ];
                case 2:
                    _ref = _state.sent(), channels = _ref.data.channels;
                    ChannelStore.setState({
                        managedChannels: channels
                    });
                    return [
                        2,
                        channels
                    ];
                case 3:
                    ChannelStore.setState({
                        managedChannelsLoading: false
                    });
                    return [
                        7
                    ];
                case 4:
                    return [
                        2
                    ];
            }
        });
    });
    return _getChannels.apply(this, arguments);
}
export function deleteManagedChannel(managedChannelId) {
    return _deleteManagedChannel.apply(this, arguments);
}
function _deleteManagedChannel() {
    _deleteManagedChannel = _async_to_generator(function(managedChannelId) {
        var user, _ChannelStore_getState, channel, removeManagedChannel, getManagedChannel, managedChannel;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.ME_CHANNEL(managedChannelId))
                    ];
                case 1:
                    _state.sent();
                    user = AuthenticationStore.getState().user;
                    _ChannelStore_getState = ChannelStore.getState(), channel = _ChannelStore_getState.channel, removeManagedChannel = _ChannelStore_getState.removeManagedChannel, getManagedChannel = _ChannelStore_getState.getManagedChannel;
                    managedChannel = getManagedChannel(managedChannelId);
                    if (managedChannel == null) {
                        return [
                            2
                        ];
                    }
                    removeManagedChannel(managedChannel._id);
                    if (!(user != null && channel != null && channel._id === managedChannel.channel._id)) return [
                        3,
                        3
                    ];
                    return [
                        4,
                        updateChannel(user._id)
                    ];
                case 2:
                    _state.sent();
                    _state.label = 3;
                case 3:
                    return [
                        2
                    ];
            }
        });
    });
    return _deleteManagedChannel.apply(this, arguments);
}
export function joinChannel(channelId) {
    return _joinChannel.apply(this, arguments);
}
function _joinChannel() {
    _joinChannel = _async_to_generator(function(channelId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.CHANNEL_JOIN, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    ChannelStore.getState().updateChannel({
                        joined: true
                    });
                    return [
                        2
                    ];
            }
        });
    });
    return _joinChannel.apply(this, arguments);
}
export function joinChannelAddModerator(channelId) {
    return _joinChannelAddModerator.apply(this, arguments);
}
function _joinChannelAddModerator() {
    _joinChannelAddModerator = _async_to_generator(function(channelId) {
        var _;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        2,
                        ,
                        3
                    ]);
                    return [
                        4,
                        API.post(APIRoutes.CHANNEL_JOIN_ADD_MODERATOR, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        3,
                        3
                    ];
                case 2:
                    _ = _state.sent();
                    return [
                        2,
                        false
                    ];
                case 3:
                    return [
                        2,
                        true
                    ];
            }
        });
    });
    return _joinChannelAddModerator.apply(this, arguments);
}
export function partChannel(channelId) {
    return _partChannel.apply(this, arguments);
}
function _partChannel() {
    _partChannel = _async_to_generator(function(channelId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.CHANNEL_PART, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    ChannelStore.getState().updateChannel({
                        joined: false
                    });
                    return [
                        2
                    ];
            }
        });
    });
    return _partChannel.apply(this, arguments);
}
export function getWebsocketToken(_) {
    return _getWebsocketToken.apply(this, arguments);
}
function _getWebsocketToken() {
    _getWebsocketToken = _async_to_generator(function(param) {
        var channelId, _ref, token;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    channelId = param.channelId;
                    return [
                        4,
                        API.get(APIRoutes.ME_WS_TOKEN, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), token = _ref.data.token;
                    return [
                        2,
                        token
                    ];
            }
        });
    });
    return _getWebsocketToken.apply(this, arguments);
}
export function lookupChannelUser(channelId, username) {
    return _lookupChannelUser.apply(this, arguments);
}
function _lookupChannelUser() {
    _lookupChannelUser = _async_to_generator(function(channelId, username) {
        var _ref, user;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.CHANNEL_LOOKUP_USER, {
                            channelId: channelId,
                            searchParams: {
                                name: username
                            }
                        })
                    ];
                case 1:
                    _ref = _state.sent(), user = _ref.data.user;
                    return [
                        2,
                        user
                    ];
            }
        });
    });
    return _lookupChannelUser.apply(this, arguments);
}
export function sendChannelMessage(channelId, message) {
    return _sendChannelMessage.apply(this, arguments);
}
function _sendChannelMessage() {
    _sendChannelMessage = _async_to_generator(function(channelId, message) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.CHANNEL_SEND, {
                            channelId: channelId,
                            body: {
                                message: message
                            }
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _sendChannelMessage.apply(this, arguments);
}
export function getChannelProviderSettings(channelId) {
    return _getChannelProviderSettings.apply(this, arguments);
}
function _getChannelProviderSettings() {
    _getChannelProviderSettings = _async_to_generator(function(channelId) {
        var _ref, providerSettings;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.CHANNEL_PROVIDER_SETTINGS, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), providerSettings = _ref.data.providerSettings;
                    return [
                        2,
                        providerSettings
                    ];
            }
        });
    });
    return _getChannelProviderSettings.apply(this, arguments);
}
export function updateChannelProviderSettings(channelId, providerSettings) {
    return _updateChannelProviderSettings.apply(this, arguments);
}
function _updateChannelProviderSettings() {
    _updateChannelProviderSettings = _async_to_generator(function(channelId, providerSettings) {
        var _ref, _ref_data, updatedProviderSettings;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.patch(APIRoutes.CHANNEL_PROVIDER_SETTINGS, {
                            channelId: channelId,
                            body: {
                                providerSettings: providerSettings
                            }
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, updatedProviderSettings = _ref_data.providerSettings;
                    return [
                        2,
                        updatedProviderSettings
                    ];
            }
        });
    });
    return _updateChannelProviderSettings.apply(this, arguments);
}
