// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* chakra renders last child with border */
.nightbot-Table-module__table-YqJRO tr:last-child td {
  border-bottom: 0 !important;
}

.nightbot-Table-module__table-YqJRO td {
  border-color: var(--chakra-colors-borderSecondary) !important;
}

.nightbot-Table-module__table-YqJRO th {
  border-width: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table.module.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;EACE,2BAA2B;AAC7B;;AAEA;EACE,6DAA6D;AAC/D;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["/* chakra renders last child with border */\n.table tr:last-child td {\n  border-bottom: 0 !important;\n}\n\n.table td {\n  border-color: var(--chakra-colors-borderSecondary) !important;\n}\n\n.table th {\n  border-width: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `nightbot-Table-module__table-YqJRO`
};
export default ___CSS_LOADER_EXPORT___;
