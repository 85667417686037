function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { AuthProviders, UserLevels } from '@/types/server';
var _obj;
var UserLevelHierarchy = (_obj = {}, _define_property(_obj, UserLevels.EVERYONE, 0), _define_property(_obj, UserLevels.REGULAR, 1), _define_property(_obj, UserLevels.SUBSCRIBER, 2), _define_property(_obj, UserLevels.TWITCH_VIP, 3), _define_property(_obj, UserLevels.SOOP_VIP, 3), _define_property(_obj, UserLevels.MODERATOR, 4), _define_property(_obj, UserLevels.ADMIN, 5), _define_property(_obj, UserLevels.OWNER, 6), _obj);
export function getUserLevelValue(userLevel) {
    return UserLevelHierarchy[userLevel];
}
export function getUserLevelsFromProviderUser(user) {
    var levels = [];
    if (user.moderator) {
        levels.push(UserLevels.MODERATOR);
    }
    if (user.subscriber) {
        levels.push(UserLevels.SUBSCRIBER);
    }
    if (user.vip === true) {
        if (user.provider === AuthProviders.TWITCH) {
            levels.push(UserLevels.TWITCH_VIP);
        }
        if (user.provider === AuthProviders.SOOP) {
            levels.push(UserLevels.SOOP_VIP);
        }
    }
    if (user.regular) {
        levels.push(UserLevels.REGULAR);
    }
    if (levels.length === 0) {
        levels.push(UserLevels.EVERYONE);
    }
    return levels;
}
