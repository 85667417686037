function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import AuthenticationStore from '@/stores/AuthenticationStore';
import ChannelStore from '@/stores/ChannelStore';
import API from '@/utils/api';
import { APIRoutes } from '@/utils/constants';
export function logoutUser() {
    AuthenticationStore.setState({
        user: null,
        accessToken: null,
        isLoggedIn: false
    });
    ChannelStore.setState({
        channel: null
    });
}
export function getCurrentUser() {
    return _getCurrentUser.apply(this, arguments);
}
function _getCurrentUser() {
    _getCurrentUser = _async_to_generator(function() {
        var _ref, user;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.ME)
                    ];
                case 1:
                    _ref = _state.sent(), user = _ref.data.user;
                    AuthenticationStore.setState({
                        user: user
                    });
                    return [
                        2,
                        user
                    ];
            }
        });
    });
    return _getCurrentUser.apply(this, arguments);
}
export function deleteCurrentUser() {
    return _deleteCurrentUser.apply(this, arguments);
}
function _deleteCurrentUser() {
    _deleteCurrentUser = _async_to_generator(function() {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.ME)
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _deleteCurrentUser.apply(this, arguments);
}
export function getAuditLogs() {
    return _getAuditLogs.apply(this, arguments);
}
function _getAuditLogs() {
    _getAuditLogs = _async_to_generator(function() {
        var _ref, logs;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.AUDIT_LOGS)
                    ];
                case 1:
                    _ref = _state.sent(), logs = _ref.data.logs;
                    return [
                        2,
                        logs
                    ];
            }
        });
    });
    return _getAuditLogs.apply(this, arguments);
}
export function getTwoFactorEnabled() {
    return _getTwoFactorEnabled.apply(this, arguments);
}
function _getTwoFactorEnabled() {
    _getTwoFactorEnabled = _async_to_generator(function() {
        var _ref, _ref_data, enabled;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.TWO_FACTOR)
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, enabled = _ref_data.twoFactor.enabled;
                    return [
                        2,
                        enabled
                    ];
            }
        });
    });
    return _getTwoFactorEnabled.apply(this, arguments);
}
export function createTwoFactor() {
    return _createTwoFactor.apply(this, arguments);
}
function _createTwoFactor() {
    _createTwoFactor = _async_to_generator(function() {
        var _ref, token;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.TWO_FACTOR)
                    ];
                case 1:
                    _ref = _state.sent(), token = _ref.data.token;
                    return [
                        2,
                        token
                    ];
            }
        });
    });
    return _createTwoFactor.apply(this, arguments);
}
export function deleteTwoFactor() {
    return _deleteTwoFactor.apply(this, arguments);
}
function _deleteTwoFactor() {
    _deleteTwoFactor = _async_to_generator(function() {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.TWO_FACTOR)
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _deleteTwoFactor.apply(this, arguments);
}
export function verifyTwoFactorCode(code) {
    return _verifyTwoFactorCode.apply(this, arguments);
}
function _verifyTwoFactorCode() {
    _verifyTwoFactorCode = _async_to_generator(function(code) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.TWO_FACTOR_VERIFY, {
                            body: {
                                code: code
                            }
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _verifyTwoFactorCode.apply(this, arguments);
}
export function getTwoFactorBackupCodes() {
    return _getTwoFactorBackupCodes.apply(this, arguments);
}
function _getTwoFactorBackupCodes() {
    _getTwoFactorBackupCodes = _async_to_generator(function() {
        var _ref, codes;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.TWO_FACTOR_BACKUP_CODES)
                    ];
                case 1:
                    _ref = _state.sent(), codes = _ref.data.codes;
                    return [
                        2,
                        codes
                    ];
            }
        });
    });
    return _getTwoFactorBackupCodes.apply(this, arguments);
}
export function regenerateTwoFactorBackupCodes() {
    return _regenerateTwoFactorBackupCodes.apply(this, arguments);
}
function _regenerateTwoFactorBackupCodes() {
    _regenerateTwoFactorBackupCodes = _async_to_generator(function() {
        var _ref, codes;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.TWO_FACTOR_BACKUP_CODES)
                    ];
                case 1:
                    _ref = _state.sent(), codes = _ref.data.codes;
                    return [
                        2,
                        codes
                    ];
            }
        });
    });
    return _regenerateTwoFactorBackupCodes.apply(this, arguments);
}
export function getManagers() {
    return _getManagers.apply(this, arguments);
}
function _getManagers() {
    _getManagers = _async_to_generator(function() {
        var _ref, managers;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.MANAGERS)
                    ];
                case 1:
                    _ref = _state.sent(), managers = _ref.data.managers;
                    return [
                        2,
                        managers
                    ];
            }
        });
    });
    return _getManagers.apply(this, arguments);
}
export function createManager(userId) {
    return _createManager.apply(this, arguments);
}
function _createManager() {
    _createManager = _async_to_generator(function(userId) {
        var _ref, manager;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.MANAGERS, {
                            body: {
                                user: userId
                            }
                        })
                    ];
                case 1:
                    _ref = _state.sent(), manager = _ref.data.manager;
                    return [
                        2,
                        manager
                    ];
            }
        });
    });
    return _createManager.apply(this, arguments);
}
export function deleteManager(managerId) {
    return _deleteManager.apply(this, arguments);
}
function _deleteManager() {
    _deleteManager = _async_to_generator(function(managerId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.MANAGER(managerId))
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _deleteManager.apply(this, arguments);
}
