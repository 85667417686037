// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-SideBarLayout-module__scrollBar-ORsp4 {
  scrollbar-gutter: stable both-edges;
  background-color: var(--chakra-colors-backgroundSecondary);
  scrollbar-color: var(--chakra-colors-borderPrimary) var(--chakra-colors-backgroundSecondary);
  background: var(--chakra-colors-backgroundSecondary);
}
`, "",{"version":3,"sources":["webpack://./src/components/layouts/SideBarLayout.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,0DAA0D;EAC1D,4FAA4F;EAC5F,oDAAoD;AACtD","sourcesContent":[".scrollBar {\n  scrollbar-gutter: stable both-edges;\n  background-color: var(--chakra-colors-backgroundSecondary);\n  scrollbar-color: var(--chakra-colors-borderPrimary) var(--chakra-colors-backgroundSecondary);\n  background: var(--chakra-colors-backgroundSecondary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollBar": `nightbot-SideBarLayout-module__scrollBar-ORsp4`
};
export default ___CSS_LOADER_EXPORT___;
