function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import Popup from '@/lib/popup';
import { PopupEventNames } from '@/types/client';
import API from '@/utils/api';
import { APIRoutes } from '@/utils/constants';
var FIVE_MINUTES = 5 * 60 * 1000;
export function getIntegrationDiscordServers(channelId) {
    return _getIntegrationDiscordServers.apply(this, arguments);
}
function _getIntegrationDiscordServers() {
    _getIntegrationDiscordServers = _async_to_generator(function(channelId) {
        var _ref, servers;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.INTEGRATIONS_DISCORD_SERVERS, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), servers = _ref.data.servers;
                    return [
                        2,
                        servers
                    ];
            }
        });
    });
    return _getIntegrationDiscordServers.apply(this, arguments);
}
export function deleteIntegrationDiscordServer(channelId, serverId) {
    return _deleteIntegrationDiscordServer.apply(this, arguments);
}
function _deleteIntegrationDiscordServer() {
    _deleteIntegrationDiscordServer = _async_to_generator(function(channelId, serverId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.INTEGRATIONS_DISCORD_SERVER(serverId), {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _deleteIntegrationDiscordServer.apply(this, arguments);
}
export function updateIntegrationDiscordServer(channelId, server) {
    return _updateIntegrationDiscordServer.apply(this, arguments);
}
function _updateIntegrationDiscordServer() {
    _updateIntegrationDiscordServer = _async_to_generator(function(channelId, server) {
        var _ref, _ref_data, updatedServer;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.put(APIRoutes.INTEGRATIONS_DISCORD_SERVER(server._id), {
                            body: server,
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, updatedServer = _ref_data.server;
                    return [
                        2,
                        updatedServer
                    ];
            }
        });
    });
    return _updateIntegrationDiscordServer.apply(this, arguments);
}
export function addDiscordIntegration(channelId, _) {
    return _addDiscordIntegration.apply(this, arguments);
}
function _addDiscordIntegration() {
    _addDiscordIntegration = _async_to_generator(function(channelId, param) {
        var code, state;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    code = param.code, state = param.state;
                    return [
                        4,
                        API.put(APIRoutes.INTEGRATIONS_DISCORD, {
                            channelId: channelId,
                            body: {
                                code: code,
                                state: state
                            }
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _addDiscordIntegration.apply(this, arguments);
}
export function addDiscordIntegrationServer(channelId, _) {
    return _addDiscordIntegrationServer.apply(this, arguments);
}
function _addDiscordIntegrationServer() {
    _addDiscordIntegrationServer = _async_to_generator(function(channelId, param) {
        var code, state, _ref, server;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    code = param.code, state = param.state;
                    return [
                        4,
                        API.post(APIRoutes.INTEGRATIONS_DISCORD_SERVERS, {
                            channelId: channelId,
                            body: {
                                code: code,
                                state: state
                            }
                        })
                    ];
                case 1:
                    _ref = _state.sent(), server = _ref.data.server;
                    return [
                        2,
                        server
                    ];
            }
        });
    });
    return _addDiscordIntegrationServer.apply(this, arguments);
}
export function getDiscordIntegration(channelId) {
    return _getDiscordIntegration.apply(this, arguments);
}
function _getDiscordIntegration() {
    _getDiscordIntegration = _async_to_generator(function(channelId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.INTEGRATIONS_DISCORD, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _getDiscordIntegration.apply(this, arguments);
}
export function disconnectDiscordIntegration(channelId) {
    return _disconnectDiscordIntegration.apply(this, arguments);
}
function _disconnectDiscordIntegration() {
    _disconnectDiscordIntegration = _async_to_generator(function(channelId) {
        var servers;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        getIntegrationDiscordServers(channelId)
                    ];
                case 1:
                    servers = _state.sent();
                    return [
                        4,
                        Promise.all(servers.map(function(server) {
                            return deleteIntegrationDiscordServer(channelId, server._id);
                        }))
                    ];
                case 2:
                    _state.sent();
                    return [
                        4,
                        API.delete(APIRoutes.INTEGRATIONS_DISCORD, {
                            channelId: channelId
                        })
                    ];
                case 3:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _disconnectDiscordIntegration.apply(this, arguments);
}
export function connectIntegration(_) {
    return _connectIntegration.apply(this, arguments);
}
function _connectIntegration() {
    _connectIntegration = _async_to_generator(function(param) {
        var state, url, popup;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    state = param.state, url = param.url;
                    popup = new Popup(url, {
                        width: 720,
                        height: 960
                    });
                    popup.open();
                    return [
                        4,
                        new Promise(function(resolve, reject) {
                            var handleMessage = function handleMessage(data) {
                                if ((data === null || data === void 0 ? void 0 : data.code) == null || (data === null || data === void 0 ? void 0 : data.state) == null) {
                                    return;
                                }
                                if (typeof (data === null || data === void 0 ? void 0 : data.error) === 'string') {
                                    var _data_error_description;
                                    return reject(new Error((_data_error_description = data.error_description) !== null && _data_error_description !== void 0 ? _data_error_description : data.error));
                                }
                                if ((data === null || data === void 0 ? void 0 : data.state) !== state) {
                                    return reject(new Error('Invalid state'));
                                }
                                isHandlingMessage = true;
                                popup.close();
                                resolve({
                                    code: data.code,
                                    state: data.state
                                });
                            };
                            var isHandlingMessage = false;
                            popup.addListener(PopupEventNames.RESULT_QUERYSTRING_UPDATE, handleMessage);
                            var timeout = setTimeout(function() {
                                return popup.close();
                            }, FIVE_MINUTES);
                            popup.once('close', function() {
                                if (timeout != null) {
                                    clearTimeout(timeout);
                                }
                                popup.removeListener(PopupEventNames.RESULT_QUERYSTRING_UPDATE, handleMessage);
                                if (!isHandlingMessage) {
                                    reject(new Error('Popup closed without message'));
                                }
                            });
                        })
                    ];
                case 1:
                    return [
                        2,
                        _state.sent()
                    ];
            }
        });
    });
    return _connectIntegration.apply(this, arguments);
}
export function connectDiscordIntegrationServer(channelId) {
    return _connectDiscordIntegrationServer.apply(this, arguments);
}
function _connectDiscordIntegrationServer() {
    _connectDiscordIntegrationServer = _async_to_generator(function(channelId) {
        var _ref, _ref_data, state, url, code;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.INTEGRATIONS_DISCORD_SERVERS, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, state = _ref_data.state, url = _ref_data.url;
                    return [
                        4,
                        connectIntegration({
                            state: state,
                            url: url
                        })
                    ];
                case 2:
                    code = _state.sent().code;
                    return [
                        4,
                        addDiscordIntegrationServer(channelId, {
                            code: code,
                            state: state
                        })
                    ];
                case 3:
                    return [
                        2,
                        _state.sent()
                    ];
            }
        });
    });
    return _connectDiscordIntegrationServer.apply(this, arguments);
}
export function connectDiscordIntegration(channelId) {
    return _connectDiscordIntegration.apply(this, arguments);
}
function _connectDiscordIntegration() {
    _connectDiscordIntegration = _async_to_generator(function(channelId) {
        var _ref, _ref_data, state, url, code;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.INTEGRATIONS_DISCORD, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, state = _ref_data.state, url = _ref_data.url;
                    return [
                        4,
                        connectIntegration({
                            state: state,
                            url: url
                        })
                    ];
                case 2:
                    code = _state.sent().code;
                    return [
                        4,
                        addDiscordIntegration(channelId, {
                            code: code,
                            state: state
                        })
                    ];
                case 3:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _connectDiscordIntegration.apply(this, arguments);
}
