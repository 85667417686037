export var faSword = {
    icon: [
        512,
        512,
        [],
        'f0000',
        'M110.1 227.6c-6.3-6.3-16.4-6.3-22.6 0l-18.8 18.8a16 16 0 0 0 -2 20.2l53.4 80.1-53.4 53.4-29.3-14.6a13.9 13.9 0 0 0 -16 2.6L4.1 405.4c-5.4 5.4-5.4 14.2 0 19.6L87 507.9c5.4 5.4 14.2 5.4 19.6 0l17.3-17.3a13.9 13.9 0 0 0 2.6-16l-14.6-29.3 53.4-53.4 80.1 53.4c6.4 4.2 14.8 3.4 20.2-2l18.8-18.8c6.3-6.3 6.3-16.4 0-22.6l-174.3-174.3zM493.7 .2L400 16 171.9 244.1l96 96L496 112l15.8-93.7c1.5-10.6-7.5-19.6-18.1-18.1z'
    ],
    iconName: 'sword',
    prefix: 'fa'
};
