import { create } from 'zustand';
import isDeepEqual from 'fast-deep-equal';
var timersByChannel = {};
var useTimerStore = create(function(set, get) {
    return {
        isLoading: false,
        channels: new Map(),
        setTimersForChannel: function(channelId, timers) {
            var channels = get().channels;
            timersByChannel[channelId] = timers;
            var newChannelTimers = {};
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                for(var _iterator = timers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var timer = _step.value;
                    newChannelTimers[timer._id] = timer;
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
            channels.set(channelId, newChannelTimers);
            set({
                channels: channels
            });
        },
        getTimersForChannel: function(channelId) {
            var channelTimers = get().channels.get(channelId);
            var oldChannelTimersList = timersByChannel[channelId];
            var newChannelTimersList = channelTimers != null ? Object.values(channelTimers) : null;
            if (newChannelTimersList == null) {
                return null;
            }
            if (isDeepEqual(oldChannelTimersList, newChannelTimersList)) {
                return oldChannelTimersList;
            }
            timersByChannel[channelId] = newChannelTimersList;
            return newChannelTimersList;
        },
        getTimer: function(channelId, timerId) {
            var channelTimers = get().channels.get(channelId);
            if (channelTimers == null) {
                return null;
            }
            var _channelTimers_timerId;
            return (_channelTimers_timerId = channelTimers[timerId]) !== null && _channelTimers_timerId !== void 0 ? _channelTimers_timerId : null;
        },
        upsertTimer: function(channelId, timer) {
            var channels = get().channels;
            var channelTimers = channels.get(channelId);
            if (channelTimers == null) {
                return;
            }
            var newChannels = new Map(channels);
            channelTimers[timer._id] = timer;
            newChannels.set(channelId, channelTimers);
            set({
                channels: newChannels
            });
        },
        deleteTimer: function(channelId, timerId) {
            var channels = get().channels;
            var channelTimers = channels.get(channelId);
            if (channelTimers == null) {
                return;
            }
            delete channelTimers[timerId];
            var newChannels = new Map(channels);
            newChannels.set(channelId, channelTimers);
            set({
                channels: newChannels
            });
        }
    };
});
export default useTimerStore;
