import React from 'react';
export default function useScript(param) {
    var src = param.src, _param_async = param.async, async = _param_async === void 0 ? true : _param_async, onBeforeLoad = param.onBeforeLoad, onLoad = param.onLoad;
    React.useEffect(function() {
        var script = document.createElement('script');
        script.src = src;
        script.async = async;
        if (onLoad != null) {
            script.onload = onLoad;
        }
        if (onBeforeLoad != null) {
            onBeforeLoad();
        }
        document.body.appendChild(script);
        return function() {
            document.body.removeChild(script);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        src,
        async
    ]);
}
