import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { selectAnatomy } from '@chakra-ui/anatomy';
// eslint-disable-next-line @typescript-eslint/unbound-method
var _createMultiStyleConfigHelpers = createMultiStyleConfigHelpers(selectAnatomy.keys), definePartsStyle = _createMultiStyleConfigHelpers.definePartsStyle, defineMultiStyleConfig = _createMultiStyleConfigHelpers.defineMultiStyleConfig;
var selectStyles = definePartsStyle({
    field: {
        _dark: {
            backgroundColor: 'whiteAlpha.50',
            borderColor: 'borderSecondary'
        },
        _light: {
            backgroundColor: 'backgroundPrimary',
            borderColor: 'borderSecondary'
        },
        cursor: 'pointer'
    }
});
export default defineMultiStyleConfig({
    baseStyle: selectStyles
});
