// TODO: ensure all buttons use variants and not custom styles
var styles = {
    baseStyle: {
        _loading: {
            cursor: 'progress'
        },
        _active: {
            boxShadow: 'outline'
        }
    },
    variants: {
        outline: {
            boxShadow: 'sm',
            borderColor: 'borderSecondary',
            _light: {
                backgroundColor: 'backgroundPrimary',
                _hover: {
                    backgroundColor: 'backgroundSecondary'
                }
            },
            _dark: {
                backgroundColor: 'whiteAlpha.50',
                _hover: {
                    backgroundColor: 'whiteAlpha.100'
                }
            }
        },
        ghost: {
            _hover: {
                color: 'textSecondary',
                backgroundColor: 'unset'
            },
            _active: {
                color: 'textSecondary',
                backgroundColor: 'unset'
            }
        },
        cta: {
            backgroundColor: 'backgroundInverse',
            color: 'textInverse',
            _loading: {
                _hover: {
                    backgroundColor: 'backgroundInverse'
                }
            },
            _light: {
                _hover: {
                    backgroundColor: 'blackAlpha.700',
                    _disabled: {
                        backgroundColor: 'backgroundInverse'
                    }
                }
            },
            _dark: {
                _hover: {
                    backgroundColor: 'whiteAlpha.700',
                    _disabled: {
                        backgroundColor: 'backgroundInverse'
                    }
                }
            }
        }
    }
};
export default styles;
