import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { switchAnatomy } from '@chakra-ui/anatomy';
// eslint-disable-next-line @typescript-eslint/unbound-method
var _createMultiStyleConfigHelpers = createMultiStyleConfigHelpers(switchAnatomy.keys), definePartsStyle = _createMultiStyleConfigHelpers.definePartsStyle, defineMultiStyleConfig = _createMultiStyleConfigHelpers.defineMultiStyleConfig;
var switchBaseStyle = definePartsStyle({
    track: {
        _checked: {
            bg: 'primary.500'
        },
        _disabled: {
            cursor: 'progress'
        }
    }
});
export default defineMultiStyleConfig({
    baseStyle: switchBaseStyle
});
