import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { StorageKeys } from '@/utils/constants';
var useAppStore = create()(persist(function(set) {
    return {
        colorMode: 'light',
        isDeveloper: false,
        language: 'en',
        setColorMode: function(colorMode) {
            return set({
                colorMode: colorMode
            });
        },
        toggleColorMode: function() {
            return set(function(state) {
                return {
                    colorMode: state.colorMode === 'light' ? 'dark' : 'light'
                };
            });
        },
        setIsDeveloper: function(isDeveloper) {
            return set({
                isDeveloper: isDeveloper
            });
        },
        setLanguage: function(language) {
            return set({
                language: language
            });
        }
    };
}, {
    name: StorageKeys.APP_STORE,
    partialize: function(param) {
        var colorMode = param.colorMode, isDeveloper = param.isDeveloper, language = param.language;
        return {
            colorMode: colorMode,
            isDeveloper: isDeveloper,
            language: language
        };
    }
}));
export default useAppStore;
