import React from 'react';
import useNativeStore from '@/stores/NativeStore';
import dayjs from 'dayjs';
import useIsNative from './useIsNative';
import { sendNativeEvent } from '@/lib/native-events';
import { NativeEventNames } from '@/utils/constants';
export default function useCurrentSongRequestOutputFile(song) {
    var currentSongOutputFormat = useNativeStore(function(state) {
        return state.currentSongOutputFormat;
    });
    var isNative = useIsNative();
    React.useEffect(function() {
        if (!isNative || currentSongOutputFormat == null || song == null) {
            return;
        }
        var output = currentSongOutputFormat;
        output.replace('{{title}}', song.track.title);
        output.replace('{{artist}}', song.track.artist);
        output.replace('{{url}}', song.track.url);
        output.replace('{{duration}}', dayjs.duration(song.track.duration, 'seconds').format('mm:ss'));
        sendNativeEvent({
            name: NativeEventNames.FILES_SET_FORMAT,
            data: output
        });
    }, [
        isNative,
        currentSongOutputFormat,
        song
    ]);
}
