function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { StorageKeys } from '@/utils/constants';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
var useChannelStore = create()(persist(function(set, get) {
    return {
        channel: null,
        managedChannels: null,
        channelLoading: false,
        managedChannelsLoading: false,
        setChannel: function(channel) {
            return set({
                channel: channel
            });
        },
        updateChannel: function(channel) {
            var prevChannel = get().channel;
            if (prevChannel == null) {
                return;
            }
            set({
                channel: _object_spread({}, prevChannel, channel)
            });
        },
        removeManagedChannel: function(managedChannelId) {
            var managedChannels = get().managedChannels;
            if (managedChannels == null) {
                return;
            }
            set({
                managedChannels: managedChannels.filter(function(channel) {
                    return channel._id !== managedChannelId;
                })
            });
        },
        getManagedChannel: function(channelId) {
            var managedChannels = get().managedChannels;
            if (managedChannels == null) {
                return null;
            }
            var _managedChannels_find;
            return (_managedChannels_find = managedChannels.find(function(channel) {
                return channel._id === channelId;
            })) !== null && _managedChannels_find !== void 0 ? _managedChannels_find : null;
        }
    };
}, {
    name: StorageKeys.CHANNEL_STORE,
    partialize: function(param) {
        var channel = param.channel;
        return {
            channel: channel
        };
    }
}));
export default useChannelStore;
