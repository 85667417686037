import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { menuAnatomy } from '@chakra-ui/anatomy';
// eslint-disable-next-line @typescript-eslint/unbound-method
var _createMultiStyleConfigHelpers = createMultiStyleConfigHelpers(menuAnatomy.keys), definePartsStyle = _createMultiStyleConfigHelpers.definePartsStyle, defineMultiStyleConfig = _createMultiStyleConfigHelpers.defineMultiStyleConfig;
var menuStyles = definePartsStyle({
    list: {
        backgroundColor: 'backgroundPrimary',
        boxShadow: 'base',
        borderColor: 'borderSecondary'
    },
    item: {
        backgroundColor: 'backgroundPrimary',
        _hover: {
            backgroundColor: 'backgroundHover'
        }
    }
});
export default defineMultiStyleConfig({
    baseStyle: menuStyles
});
