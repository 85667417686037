import { StorageKeys } from '@/utils/constants';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
var useNativeStore = create()(persist(function(set) {
    return {
        source: null,
        origin: null,
        negotiated: false,
        settings: new Map(),
        currentSongOutputFormat: null,
        setCurrentSongOutputFormat: function(format) {
            return set({
                currentSongOutputFormat: format
            });
        },
        setSource: function(source) {
            return set({
                source: source
            });
        },
        setOrigin: function(origin) {
            return set({
                origin: origin
            });
        },
        setNegotiated: function(negotiated) {
            return set({
                negotiated: negotiated
            });
        },
        setSettings: function(settings) {
            return set({
                settings: settings
            });
        }
    };
}, {
    name: StorageKeys.NATIVE_STORE,
    partialize: function(param) {
        var currentSongOutputFormat = param.currentSongOutputFormat, settings = param.settings;
        return {
            currentSongOutputFormat: currentSongOutputFormat,
            settings: settings
        };
    }
}));
export default useNativeStore;
