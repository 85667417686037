export const UserLevels = {
  admin: 'admin',
  owner: 'owner',
  moderator: 'moderator',
  twitch_vip: 'twitch_vip',
  soop_vip: 'soop_vip',
  regular: 'regular',
  subscriber: 'subscriber',
  everyone: 'everyone',
};

export const AuthProviders = {
  twitch: 'twitch',
  youtube: 'youtube',
  trovo: 'trovo',
  soop: 'soop',
  noice: 'noice',
};

export const Providers = {
  twitch: 'twitch',
  youtube: 'youtube',
  discord: 'discord',
  trovo: 'trovo',
  soop: 'soop',
  noice: 'noice',
};

export const ProviderNames = {
  twitch: 'Twitch',
  youtube: 'YouTube',
  discord: 'Discord',
  trovo: 'Trovo',
  soop: 'Soop',
  noice: 'Noice',
};

export const ShortProviderSlugs = {
  twitch: 't',
  youtube: 'y',
  trovo: 'trovo',
  soop: 'soop',
  noice: 'noice',
};

export const LongProviderSlugs = {
  t: 'twitch',
  y: 'youtube',
  trovo: 'trovo',
  soop: 'soop',
  noice: 'noice',
};

export const DefaultProviderBots = {
  twitch: '554eb4b73ee1bab94698bae7',
  youtube: '566e4791a8f576a8547e27e9',
  mixer: '5db656dd30a594e9472b612c',
  trovo: '600cc39f3d61dd6e3a5c3264',
  noice: '65cfaf3abf7ce648b259ece9',
  soop: '6736df5dac386ee4930bd493',
};

export const SubscriptionPlans = {
  free: '554eb4df3ee1bab94698bae8',
  pro: '56b04e4f83c368de74e85435',
  grandfathered: '56cd75bdbd1c47248f7951ad',
};

export const SongRequestProviders = {
  soundcloud: 'soundcloud',
  youtube: 'youtube',
};

export const SongRequestProviderNames = {
  soundcloud: 'SoundCloud',
  youtube: 'YouTube',
};

export const SongRequestPlaylists = {
  channel: 'channel',
  monstercat: 'monstercat',
  twitch_music_library: 'twitch_music_library',
};

export const SongRequestPlaylistNames = {
  channel: 'Channel',
  monstercat: 'Monstercat',
  twitch_music_library: 'Twitch Music Library',
};

export const SongRequestPlaylistUserIds = {
  monstercat: '5d6d8042f8ffc972d9ede93c',
  twitch_music_library: '5d6d804d18ce3c21063932cf',
};

export const UserSerializationFields = {
  settings: 0,
};
