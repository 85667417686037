import { create } from 'zustand';
import isDeepEqual from 'fast-deep-equal';
var commandsByChannel = {};
var useDefaultCommandStore = create(function(set, get) {
    return {
        isLoading: false,
        channels: new Map(),
        setCommandsForChannel: function(channelId, commands) {
            var channels = get().channels;
            commandsByChannel[channelId] = commands;
            var newChannelCommands = {};
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                for(var _iterator = commands[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var command = _step.value;
                    newChannelCommands[command._name] = command;
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
            channels.set(channelId, newChannelCommands);
            set({
                channels: channels
            });
        },
        getCommandsForChannel: function(channelId) {
            var channelCommands = get().channels.get(channelId);
            var oldChannelCommandsList = commandsByChannel[channelId];
            var newChannelCommandsList = channelCommands != null ? Object.values(channelCommands) : null;
            if (newChannelCommandsList == null) {
                return null;
            }
            if (isDeepEqual(oldChannelCommandsList, newChannelCommandsList)) {
                return oldChannelCommandsList;
            }
            commandsByChannel[channelId] = newChannelCommandsList;
            return newChannelCommandsList;
        },
        getCommand: function(channelId, commandName) {
            var channelCommands = get().channels.get(channelId);
            if (channelCommands == null) {
                return null;
            }
            var _channelCommands_commandName;
            return (_channelCommands_commandName = channelCommands[commandName]) !== null && _channelCommands_commandName !== void 0 ? _channelCommands_commandName : null;
        },
        upsertCommand: function(channelId, command) {
            var channels = get().channels;
            var channelCommands = channels.get(channelId);
            if (channelCommands == null) {
                return;
            }
            channelCommands[command._name] = command;
            channels.set(channelId, channelCommands);
            set({
                channels: channels
            });
        }
    };
});
export default useDefaultCommandStore;
