function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import API from '@/utils/api';
import { APIRoutes } from '@/utils/constants';
import AuthenticationStore from '@/stores/AuthenticationStore';
export function openProviderAuthentication(provider) {
    return _openProviderAuthentication.apply(this, arguments);
}
function _openProviderAuthentication() {
    _openProviderAuthentication = _async_to_generator(function(provider) {
        var _ref, _ref_data, url, state;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.AUTH_PROVIDER(provider), {
                            withVersion: false
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, url = _ref_data.url, state = _ref_data.state;
                    AuthenticationStore.setState({
                        authState: state
                    });
                    if (provider !== 'sso') {
                        window.open(url, '_self');
                    } else {
                        window.open(url, '_blank');
                    }
                    return [
                        2
                    ];
            }
        });
    });
    return _openProviderAuthentication.apply(this, arguments);
}
export function connectProvider(provider, code, state) {
    return _connectProvider.apply(this, arguments);
}
function _connectProvider() {
    _connectProvider = _async_to_generator(function(provider, code, state) {
        var authState, response;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    authState = AuthenticationStore.getState().authState;
                    if (provider !== 'sso' && (authState == null || state !== authState)) {
                        throw new Error('Invalid state');
                    }
                    return [
                        4,
                        API.post(APIRoutes.AUTH_PROVIDER(provider), {
                            body: {
                                code: code,
                                state: state
                            },
                            withVersion: false
                        })
                    ];
                case 1:
                    response = _state.sent();
                    return [
                        2,
                        response.data != null ? {
                            accessToken: response.data.accessToken,
                            unverified2FA: response.data.unverified2FA
                        } : null
                    ];
            }
        });
    });
    return _connectProvider.apply(this, arguments);
}
export function connectTwoFactorAuthentication(code, remember) {
    return _connectTwoFactorAuthentication.apply(this, arguments);
}
function _connectTwoFactorAuthentication() {
    _connectTwoFactorAuthentication = _async_to_generator(function(code, remember) {
        var _ref, accessToken;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.AUTH_TWO_FACTOR, {
                            body: {
                                code: code,
                                remember: remember
                            },
                            withVersion: false
                        })
                    ];
                case 1:
                    _ref = _state.sent(), accessToken = _ref.data.accessToken;
                    return [
                        2,
                        {
                            accessToken: accessToken
                        }
                    ];
            }
        });
    });
    return _connectTwoFactorAuthentication.apply(this, arguments);
}
export function getOAuth2Scopes() {
    return _getOAuth2Scopes.apply(this, arguments);
}
function _getOAuth2Scopes() {
    _getOAuth2Scopes = _async_to_generator(function() {
        var data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.OAUTH2_SCOPES, {
                            withVersion: false
                        })
                    ];
                case 1:
                    data = _state.sent().data;
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return _getOAuth2Scopes.apply(this, arguments);
}
export function getOAuth2Application(clientId) {
    return _getOAuth2Application.apply(this, arguments);
}
function _getOAuth2Application() {
    _getOAuth2Application = _async_to_generator(function(clientId) {
        var _ref, application;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.OAUTH2_APPLICATION(clientId), {
                            withVersion: false
                        })
                    ];
                case 1:
                    _ref = _state.sent(), application = _ref.data;
                    return [
                        2,
                        application
                    ];
            }
        });
    });
    return _getOAuth2Application.apply(this, arguments);
}
export function authorizeOAuth2Application(allow, params) {
    return _authorizeOAuth2Application.apply(this, arguments);
}
function _authorizeOAuth2Application() {
    _authorizeOAuth2Application = _async_to_generator(function(allow, params) {
        var _ref, url;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.OAUTH2_AUTHORIZE, {
                            searchParams: params,
                            body: {
                                allow: allow
                            },
                            withVersion: false
                        })
                    ];
                case 1:
                    _ref = _state.sent(), url = _ref.data.url;
                    window.location.href = url;
                    return [
                        2
                    ];
            }
        });
    });
    return _authorizeOAuth2Application.apply(this, arguments);
}
export function deleteOAuth2Authorization(clientId) {
    return _deleteOAuth2Authorization.apply(this, arguments);
}
function _deleteOAuth2Authorization() {
    _deleteOAuth2Authorization = _async_to_generator(function(clientId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.OAUTH2_APPLICATION_DISCONNECT(clientId), {
                            withVersion: false
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _deleteOAuth2Authorization.apply(this, arguments);
}
export function getOAuth2Applications() {
    return _getOAuth2Applications.apply(this, arguments);
}
function _getOAuth2Applications() {
    _getOAuth2Applications = _async_to_generator(function() {
        var data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.OAUTH2_APPLICATIONS, {
                            withVersion: false
                        })
                    ];
                case 1:
                    data = _state.sent().data;
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return _getOAuth2Applications.apply(this, arguments);
}
export function createOAuth2Application(name) {
    return _createOAuth2Application.apply(this, arguments);
}
function _createOAuth2Application() {
    _createOAuth2Application = _async_to_generator(function(name) {
        var data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.OAUTH2_APPLICATIONS, {
                            body: {
                                name: name
                            },
                            withVersion: false
                        })
                    ];
                case 1:
                    data = _state.sent().data;
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return _createOAuth2Application.apply(this, arguments);
}
export function deleteOAuth2Application(clientId) {
    return _deleteOAuth2Application.apply(this, arguments);
}
function _deleteOAuth2Application() {
    _deleteOAuth2Application = _async_to_generator(function(clientId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.OAUTH2_APPLICATION(clientId), {
                            withVersion: false
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return _deleteOAuth2Application.apply(this, arguments);
}
export function updateOAuth2Application(application) {
    return _updateOAuth2Application.apply(this, arguments);
}
function _updateOAuth2Application() {
    _updateOAuth2Application = _async_to_generator(function(application) {
        var resetClientSecret, data;
        var _arguments = arguments;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    resetClientSecret = _arguments.length > 1 && _arguments[1] !== void 0 ? _arguments[1] : false;
                    return [
                        4,
                        API.patch(APIRoutes.OAUTH2_APPLICATION(application.clientId), {
                            body: _object_spread_props(_object_spread({}, application), {
                                clientSecret: resetClientSecret
                            }),
                            withVersion: false
                        })
                    ];
                case 1:
                    data = _state.sent().data;
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return _updateOAuth2Application.apply(this, arguments);
}
export function getSSOToken() {
    return _getSSOToken.apply(this, arguments);
}
function _getSSOToken() {
    _getSSOToken = _async_to_generator(function() {
        var authState, _ref, _ref_data, accessToken, unverified2FA;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    authState = AuthenticationStore.getState().authState;
                    if (authState == null) {
                        throw new Error('Invalid state');
                    }
                    return [
                        4,
                        API.get(APIRoutes.AUTH_SSO_TOKEN, {
                            searchParams: {
                                state: authState
                            },
                            withVersion: false
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, accessToken = _ref_data.accessToken, unverified2FA = _ref_data.unverified2FA;
                    return [
                        2,
                        {
                            accessToken: accessToken,
                            unverified2FA: unverified2FA
                        }
                    ];
            }
        });
    });
    return _getSSOToken.apply(this, arguments);
}
