function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import TimerStore from '@/stores/TimerStore';
import API from '@/utils/api';
import { APIRoutes } from '@/utils/constants';
export function getTimers(channelId) {
    return _getTimers.apply(this, arguments);
}
function _getTimers() {
    _getTimers = _async_to_generator(function(channelId) {
        var _ref, timers;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    TimerStore.setState({
                        isLoading: true
                    });
                    return [
                        4,
                        API.get(APIRoutes.TIMERS, {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), timers = _ref.data.timers;
                    TimerStore.getState().setTimersForChannel(channelId, timers);
                    TimerStore.setState({
                        isLoading: false
                    });
                    return [
                        2,
                        timers
                    ];
            }
        });
    });
    return _getTimers.apply(this, arguments);
}
export function createTimer(channelId, timer) {
    return _createTimer.apply(this, arguments);
}
function _createTimer() {
    _createTimer = _async_to_generator(function(channelId, timer) {
        var _ref, _ref_data, newTimer;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.post(APIRoutes.TIMERS, {
                            body: timer,
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, newTimer = _ref_data.timer;
                    TimerStore.getState().upsertTimer(channelId, newTimer);
                    return [
                        2,
                        newTimer
                    ];
            }
        });
    });
    return _createTimer.apply(this, arguments);
}
export function getTimer(channelId, timerId) {
    return _getTimer.apply(this, arguments);
}
function _getTimer() {
    _getTimer = _async_to_generator(function(channelId, timerId) {
        var _ref, timer;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.get(APIRoutes.TIMER(timerId), {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _ref = _state.sent(), timer = _ref.data.timer;
                    TimerStore.getState().upsertTimer(channelId, timer);
                    return [
                        2,
                        timer
                    ];
            }
        });
    });
    return _getTimer.apply(this, arguments);
}
export function updateTimer(channelId, timer) {
    return _updateTimer.apply(this, arguments);
}
function _updateTimer() {
    _updateTimer = _async_to_generator(function(channelId, timer) {
        var _ref, _ref_data, newTimer;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.put(APIRoutes.TIMER(timer._id), {
                            channelId: channelId,
                            body: timer
                        })
                    ];
                case 1:
                    _ref = _state.sent(), _ref_data = _ref.data, newTimer = _ref_data.timer;
                    TimerStore.getState().upsertTimer(channelId, newTimer);
                    return [
                        2,
                        newTimer
                    ];
            }
        });
    });
    return _updateTimer.apply(this, arguments);
}
export function deleteTimer(channelId, timerId) {
    return _deleteTimer.apply(this, arguments);
}
function _deleteTimer() {
    _deleteTimer = _async_to_generator(function(channelId, timerId) {
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        API.delete(APIRoutes.TIMER(timerId), {
                            channelId: channelId
                        })
                    ];
                case 1:
                    _state.sent();
                    TimerStore.getState().deleteTimer(channelId, timerId);
                    return [
                        2
                    ];
            }
        });
    });
    return _deleteTimer.apply(this, arguments);
}
