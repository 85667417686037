function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { GiveawayType } from '@/types/client';
import { UserLevels } from '@/types/server';
export var SENTRY_DSN = 'https://847684429e00e5252f60d268975dc1a0@o23210.ingest.us.sentry.io/4507855374647296';
export var WebRoutes = {
    HOME: '/',
    DASHBOARD: '/dashboard',
    COMMANDS: '/commands',
    COMMANDS_CUSTOM: '/commands/custom',
    COMMANDS_CUSTOM_CREATE: '/commands/custom/create',
    COMMANDS_CUSTOM_EDIT: '/commands/custom/:commandId',
    COMMANDS_DEFAULT: '/commands/default',
    LOGS: '/logs',
    LOGS_MESSAGES: '/logs/messages',
    LOGS_TIMEOUTS: '/logs/timeouts',
    TIMERS: '/timers',
    TIMERS_CREATE: '/timers/create',
    TIMERS_EDIT: '/timers/:timerId',
    REGULARS: '/regulars',
    /**
   * @deprecated Use {@link WebRoutes.SETTINGS} instead.
   */ ACCOUNT: '/account',
    /**
   * @deprecated Use {@link WebRoutes.SETTINGS_PANEL} instead.
   */ ACCOUNT_PANEL: '/account/:panel',
    LOGIN: '/login',
    TWO_FACTOR: '/twofactor',
    LOGIN_AUTHORIZED: '/login/authorized',
    LOGIN_SSO: '/login/sso',
    TERMS: '/terms',
    PRIVACY: '/privacy',
    OAUTH2_AUTHORIZE: '/oauth2/authorize',
    LOGIN_CALLBACK: '/login/:provider/callback',
    GIVEAWAYS: '/giveaways',
    MODERATION: '/moderation',
    /**
   * @deprecated Use {@link WebRoutes.MODERATION} instead.
   */ SPAM_PROTECTION: '/spam_protection',
    PUBLIC_CHANNEL: '/:provider/:username',
    PUBLIC_CHANNEL_COMMANDS: '/:provider/:username/commands',
    PUBLIC_CHANNEL_SONG_REQUESTS: '/:provider/:username/song_requests',
    PUBLIC_CHANNEL_SONG_REQUESTS_PLAYLIST: '/:provider/:username/song_requests/playlist',
    INTEGRATIONS: '/integrations',
    INTEGRATIONS_DISCORD: '/integrations/discord',
    POPUP_FLOW_CALLBACK: '/popup-flow/callback',
    SONG_REQUESTS: '/song_requests',
    SONG_REQUESTS_PLAYLIST: '/song_requests/playlist',
    SONG_REQUESTS_SETTINGS: '/song_requests/settings',
    SETTINGS_PANEL: '/settings/:panel',
    SETTINGS: '/settings',
    OAUTH2_APPLICATION_SETTINGS: '/applications/:applicationId/settings',
    SPAM_PROTECTION_FILTER: '/moderation/:filterType'
};
export var CHANNEL_JOINED_REQUIRED_WEB_ROUTES = new Set([
    WebRoutes.COMMANDS,
    WebRoutes.LOGS,
    WebRoutes.TIMERS,
    WebRoutes.REGULARS,
    WebRoutes.GIVEAWAYS,
    WebRoutes.MODERATION,
    WebRoutes.SONG_REQUESTS
]);
export var DynamicWebRoutes = {
    LOGIN_CALLBACK: function(provider) {
        return "/login/".concat(encodeURIComponent(provider), "/callback");
    },
    PUBLIC_CHANNEL: function(provider, username) {
        return "/".concat(encodeURIComponent(provider), "/").concat(encodeURIComponent(username));
    },
    PUBLIC_CHANNEL_COMMANDS: function(provider, username) {
        return "/".concat(encodeURIComponent(provider), "/").concat(encodeURIComponent(username), "/commands");
    },
    PUBLIC_CHANNEL_SONG_REQUESTS: function(provider, username) {
        return "/".concat(encodeURIComponent(provider), "/").concat(encodeURIComponent(username), "/song_requests");
    },
    PUBLIC_CHANNEL_SONG_REQUESTS_PLAYLIST: function(provider, username) {
        return "/".concat(encodeURIComponent(provider), "/").concat(encodeURIComponent(username), "/song_requests/playlist");
    },
    OAUTH2_APPLICATION_SETTINGS: function(clientId) {
        return "/applications/".concat(encodeURIComponent(clientId), "/settings");
    },
    COMMANDS_CUSTOM_EDIT: function(commandId) {
        return "/commands/custom/".concat(encodeURIComponent(commandId));
    },
    TIMERS_EDIT: function(timerId) {
        return "/timers/".concat(encodeURIComponent(timerId));
    },
    SPAM_PROTECTION_FILTER: function(filterType) {
        return "/moderation/".concat(encodeURIComponent(filterType));
    },
    SETTINGS: function(section) {
        return "/settings/".concat(encodeURIComponent(section));
    }
};
export var NavItemKeys;
(function(NavItemKeys) {
    NavItemKeys["DASHBOARD"] = "DASHBOARD";
})(NavItemKeys || (NavItemKeys = {}));
export var StorageKeys;
(function(StorageKeys) {
    StorageKeys["AUTHENTICATION_STORE"] = "AUTHENTICATION_STORE";
    StorageKeys["CHANNEL_STORE"] = "CHANNEL_STORE";
    StorageKeys["GIVEAWAY_STORE"] = "GIVEAWAY_STORE";
    StorageKeys["NATIVE_STORE"] = "NATIVE_STORE";
    StorageKeys["APP_STORE"] = "APP_STORE";
    StorageKeys["LEGACY_ACCESS_TOKEN"] = "session_token";
})(StorageKeys || (StorageKeys = {}));
export var NativeEventNames;
(function(NativeEventNames) {
    NativeEventNames["ERROR"] = "error";
    NativeEventNames["NEGOTIATION"] = "nightbot.negotiation";
    NativeEventNames["NEGOTIATED"] = "nightbot.negotiated";
    NativeEventNames["SETTINGS"] = "nightbot.settings";
    NativeEventNames["FILES_SET_FORMAT"] = "files.setFormat";
    NativeEventNames["AUTODJ_STOP_PLAYING"] = "autodj.stopPlaying";
    NativeEventNames["AUTODJ_NOW_PLAYING"] = "autodj.nowPlaying";
    NativeEventNames["AUTODJ_TOGGLE_PLAY_PAUSE"] = "autodj.togglePlayPause";
    NativeEventNames["AUTODJ_SKIP"] = "autodj.skip";
    NativeEventNames["AUTODJ_VOLUME_UP"] = "autodj.volumeUp";
    NativeEventNames["AUTODJ_VOLUME_DOWN"] = "autodj.volumeDown";
})(NativeEventNames || (NativeEventNames = {}));
export var APIRoutes = {
    ME: '/me',
    CHANNEL: '/channel',
    ME_CHANNELS: '/me/channels',
    ME_CHANNEL: function(channelId) {
        return "/me/channels/".concat(encodeURIComponent(channelId));
    },
    CHANNELS_PROVIDER_NAME: function(provider, channelName) {
        return "/channels/".concat(encodeURIComponent(provider), "/").concat(encodeURIComponent(channelName));
    },
    CHANNEL_LOOKUP_USER: '/channel/lookup_user',
    CHANNEL_SEND: '/channel/send',
    AUTH_PROVIDER: function(provider) {
        return "/auth/".concat(encodeURIComponent(provider));
    },
    AUTH_TWO_FACTOR: '/auth/2fa',
    AUTH_SSO_TOKEN: '/auth/sso/token',
    OAUTH2_SCOPES: '/oauth2/scopes',
    OAUTH2_AUTHORIZE: '/oauth2/authorize',
    OAUTH2_APPLICATIONS: '/oauth2/applications',
    OAUTH2_APPLICATION: function(clientId) {
        return "/oauth2/applications/".concat(encodeURIComponent(clientId));
    },
    OAUTH2_APPLICATION_DISCONNECT: function(clientId) {
        return "/oauth2/applications/".concat(encodeURIComponent(clientId), "/disconnect");
    },
    COMMANDS: '/commands',
    COMMANDS_DEFAULT: '/commands/default',
    COMMAND: function(commandId) {
        return "/commands/".concat(encodeURIComponent(commandId));
    },
    COMMAND_DEFAULT: function(commandName) {
        return "/commands/default/".concat(encodeURIComponent(commandName));
    },
    REGULARS: '/regulars',
    REGULAR: function(regularId) {
        return "/regulars/".concat(encodeURIComponent(regularId));
    },
    TIMERS: '/timers',
    TIMER: function(timerId) {
        return "/timers/".concat(encodeURIComponent(timerId));
    },
    LOGS: '/logs',
    LOGS_TIMEOUTS: '/logs/timeouts',
    STATS: '/stats',
    STATS_CHATTERS: '/stats/chatters',
    STATS_COMMANDS: '/stats/commands',
    CHANNEL_JOIN: '/channel/join',
    CHANNEL_JOIN_ADD_MODERATOR: '/channel/join/add_moderator',
    CHANNEL_PART: '/channel/part',
    CHANNEL_PROVIDER_SETTINGS: '/channel/provider_settings',
    ME_WS_TOKEN: '/me/ws_token',
    SPAM_PROTECTION: '/spam_protection',
    SPAM_PROTECTION_FILTER: function(filterId) {
        return "/spam_protection/".concat(encodeURIComponent(filterId));
    },
    INTEGRATIONS_DISCORD: '/integrations/discord',
    INTEGRATIONS_DISCORD_SERVERS: '/integrations/discord/servers',
    INTEGRATIONS_DISCORD_SERVER: function(serverId) {
        return "/integrations/discord/servers/".concat(encodeURIComponent(serverId));
    },
    SONG_REQUESTS: '/song_requests',
    SONG_REQUESTS_PLAYLIST: '/song_requests/playlist',
    SONG_REQUESTS_PLAYLIST_ITEM: function(trackId) {
        return "/song_requests/playlist/".concat(encodeURIComponent(trackId));
    },
    SONG_REQUESTS_PLAYLIST_IMPORT: '/song_requests/playlist/import',
    SONG_REQUESTS_QUEUE: '/song_requests/queue',
    SONG_REQUESTS_QUEUE_ITEM: function(trackId) {
        return "/song_requests/queue/".concat(encodeURIComponent(trackId));
    },
    SONG_REQUESTS_QUEUE_ITEM_PROMOTE: function(trackId) {
        return "/song_requests/queue/".concat(encodeURIComponent(trackId), "/promote");
    },
    SONG_REQUESTS_QUEUE_ITEM_PLAY: function(trackId) {
        return "/song_requests/queue/".concat(encodeURIComponent(trackId), "/play");
    },
    SONG_REQUESTS_QUEUE_ORDER: '/song_requests/queue/order',
    AUDIT_LOGS: '/me/audit_logs',
    TWO_FACTOR: '/me/twofactor',
    TWO_FACTOR_BACKUP_CODES: '/me/twofactor/backup_codes',
    TWO_FACTOR_VERIFY: '/me/twofactor/verify',
    MANAGERS: '/me/managers',
    MANAGER: function(managerId) {
        return "/me/managers/".concat(encodeURIComponent(managerId));
    }
};
export var ExternalUrls = {
    X: 'https://x.com/nightbotapp',
    TWITCH: 'https://www.twitch.tv/nightbot',
    YOUTUBE: 'https://www.youtube.com/@nightbot',
    DISCORD: 'https://discord.gg/nightdev',
    TROVO_STUDIO_ROLES_MANAGE: 'https://studio.trovo.live/role/rolesmanage',
    NOICE_STUDIO: 'https://studio.noice.com',
    DOCS: 'https://docs.nightbot.tv',
    API_DOCS: 'https://api-docs.nightbot.tv',
    COMMUNITY_FORUM: 'https://community.nightdev.com/c/nightbot',
    YOUTUBE_COMMUNITY_SETTINGS: 'https://studio.youtube.com/?d=sd&sds=community',
    TWITCH_CHAT: function(channelName) {
        return "https://www.twitch.tv/popout/".concat(encodeURIComponent(channelName), "/chat");
    },
    YOUTUBE_NIGHTBOT_CHANNEL: 'https://www.youtube.com/channel/UCSvjQBDgYDB5TGVmCZObcwA',
    NIGHTDEV_NIGHTBOT_PRODUCT_LEGAL: 'https://nightdev.com/contact?product=nightbot&topic=legal',
    EDAA_OPT_OUT_PLATFORM: 'http://www.youronlinechoices.com',
    NAI_OPT_OUT_PLATFORM: 'http://www.networkadvertising.org/choices',
    DAA_OPT_OUT_PLATFORM: 'http://optout.aboutads.info',
    NOICE_SETTINGS: 'https://noice.com/settings',
    NOICE_PRIVACY_POLICY: 'https://legal.noice.com/hc/en-us/articles/14136223776541-Privacy-Policy',
    TROVO_SETTINGS: 'https://trovo.live/settings/account',
    TROVO_PRIVACY_POLICY: 'https://trovo.live/policy/privacy-notice.html',
    GOOGLE_PRIVACY_POLICY: 'https://policies.google.com/privacy',
    TWILIO_PRIVACY_POLICY: 'https://www.twilio.com/en-us/legal/privacy',
    PADDLE_PRIVACY_POLICY: 'https://www.paddle.com/legal/privacy',
    HCAPTCHA_PRIVACY_POLICY: 'https://www.hcaptcha.com/privacy',
    SENTRY_PRIVACY_POLICY: 'https://sentry.io/privacy',
    SOUNDCLOUD_PRIVACY_POLICY: 'https://soundcloud.com/pages/privacy',
    TWITCH_PRIVACY_POLICY: 'https://www.twitch.tv/p/legal/privacy-notice',
    TWITCH_CONNECTIONS: 'https://www.twitch.tv/settings/connections',
    GOOGLE_YOUTUBE_PERMISSIONS: 'https://security.google.com/settings/security/permissions',
    DISCORD_PRIVACY_POLICY: 'https://discord.com/privacy',
    DISCORD_AUTHORIZED_APPS: 'https://discord.com/settings/authorized-apps',
    YOUTUBE_TERMS_OF_SERVICE: 'https://www.youtube.com/t/terms',
    NIGHTDEV_EMAIL: 'https://nightdev.com/contact?product=nightbot',
    NIGHTDEV: 'https://nightdev.com/'
};
export var TABLE_LIMITS = [
    10,
    25,
    50,
    100
];
var _obj;
export var UserLevelColorMap = (_obj = {}, _define_property(_obj, UserLevels.EVERYONE, 'primary'), _define_property(_obj, UserLevels.SUBSCRIBER, 'pink'), _define_property(_obj, UserLevels.REGULAR, 'blue'), _define_property(_obj, UserLevels.MODERATOR, 'green'), _define_property(_obj, UserLevels.TWITCH_VIP, 'purple'), _define_property(_obj, UserLevels.SOOP_VIP, 'purple'), _define_property(_obj, UserLevels.ADMIN, 'red'), _define_property(_obj, UserLevels.OWNER, 'red'), _obj);
export var SearchParams = {
    RETURN_TO: 'return_to'
};
export var LuckBasedGiveaways = new Set([
    GiveawayType.ACTIVE_USER,
    GiveawayType.KEYWORD
]);
export var LandingPageAnchorIds;
(function(LandingPageAnchorIds) {
    LandingPageAnchorIds["FEATURES"] = "features";
    LandingPageAnchorIds["LEARN_MORE"] = "learn_more";
})(LandingPageAnchorIds || (LandingPageAnchorIds = {}));
var _obj1;
export var LandingPageAnchorLinks = (_obj1 = {}, _define_property(_obj1, "features", "".concat(WebRoutes.HOME, "#", "features")), _define_property(_obj1, "learn_more", "".concat(WebRoutes.HOME, "#", "learn_more")), _obj1);
