import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { modalAnatomy } from '@chakra-ui/anatomy';
// eslint-disable-next-line @typescript-eslint/unbound-method
var _createMultiStyleConfigHelpers = createMultiStyleConfigHelpers(modalAnatomy.keys), definePartsStyle = _createMultiStyleConfigHelpers.definePartsStyle, defineMultiStyleConfig = _createMultiStyleConfigHelpers.defineMultiStyleConfig;
var selectStyles = definePartsStyle({
    dialogContainer: {
        borderWidth: 1
    }
});
export default defineMultiStyleConfig({
    baseStyle: selectStyles
});
