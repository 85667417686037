export var WidgetEvent;
(function(WidgetEvent) {
    WidgetEvent["PLAY"] = "play";
    WidgetEvent["PAUSE"] = "pause";
    WidgetEvent["FINISH"] = "finish";
    WidgetEvent["SEEK"] = "seek";
    WidgetEvent["READY"] = "ready";
    WidgetEvent["PLAY_PROGRESS"] = "play-progress";
    WidgetEvent["LOADING_PROGRESS"] = "loading-progress";
    WidgetEvent["ERROR"] = "error";
})(WidgetEvent || (WidgetEvent = {}));
